<template>
    <div>
        <b-form-group :label="$t(labelPrefix+'documents')">
            <multiselect
                v-model="inputVal.documents"
                :options="Object.keys(getDocumentsList())"
                :multiple="true"
                :custom-label="opt => getDocumentsList()[opt]"
            >
            </multiselect>
        </b-form-group>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'GenerateDocumentsOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getDocuments']),
        getDocumentsList() {
            let result = {}
            result['kva'] = this.$t('invoices.document.kva')

            this.getDocuments().forEach(document => {
                result[document] = this.$t('invoices.document.' + document)
            })
            return result
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>